import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
import {
    Button,
    Row,
    Col,
    Container,
    Header,
    Main,
    Footer,
    Image,
    Tabs,
    TabPane,
    Form,
    FormItem,
    Input,
    Avatar,
    Message,
    MessageBox,
    Progress,
    Checkbox,
    Radio,
    Pagination,
    Skeleton,
    SkeletonItem,
    Notification,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Tooltip,
    Popover,
    Popconfirm,
} from 'element-ui';

Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Image);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Avatar);
Vue.use(Progress);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Pagination);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Tooltip);
Vue.use(Popover);
Vue.use(Popconfirm);

Vue.prototype.$message = Message;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$notify = Notification;


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
