/**
 * 框架全局配置
 */
export default {

    // 用户信息接口
    userUrl: '/auth/user',
    // 自定义解析接口用户信息
    parseUser(res) {
        // code为0是成功, 不一样可以处理如: {code: res.code === 200 ? 0 : res.code, msg: res.message}
        let result = { code: res.code, msg: res.msg };
        if (res.data) {
            result.data = Object.assign({}, res.data, {
                // 姓名和头像会显示在顶栏, 字段不一样可以在这处理, 如:
                //avatar: res.data.avatarUrl,
                //nickname: res.data.userName,
                // 角色和权限信息, 需要为string数组类型
                roles: res.data.roles ? res.data.roles.map(d => d.roleCode) : [],
                authorities: res.data.authorities ? res.data.authorities.map(d => d.authority) : []
            });
        }
        return result;
    },
    // token传递的header名称
    tokenHeaderName: 'token',
    // token存储的名称
    tokenStoreName: 'token',
    timeStoreName: 'access_time',
    // 用户信息存储的名称
    userStoreName: '_user',
    MP3_URL: '/static/audio/',


    /**
     * 获取缓存的token的方法
     * @returns {string}
     */
    takeToken() {
        let token = localStorage.getItem(this.tokenStoreName);
        if (!token) {
            token = sessionStorage.getItem(this.tokenStoreName);
        }
        return token;
    },
    /**
     * 缓存token的方法
     * @param token
     * @param remember 是否永久存储
     */
    cacheToken(token, remember) {
        localStorage.removeItem(this.tokenStoreName);
        sessionStorage.removeItem(this.tokenStoreName);
        if (token) {
            if (remember) {
                localStorage.setItem(this.tokenStoreName, token);
            } else {
                sessionStorage.setItem(this.tokenStoreName, token);
            }
        }
    },
    /**
     * 获取缓存的用户信息
     * @returns {object}
     */
    takeUser() {
        try {
            return JSON.parse(localStorage.getItem(this.userStoreName)) || {};
        } catch (e) {
            console.error(e);
        }
        return {};
    },
    /**
     * 缓存用户信息
     * @param user
     */
    cacheUser(user) {
        if (user) {
            localStorage.setItem(this.userStoreName, JSON.stringify(user));
        } else {
            localStorage.removeItem(this.userStoreName);
        }
    },

    /*清理缓存*/
    clear() {
        localStorage.removeItem(this.tokenStoreName);
        sessionStorage.removeItem(this.tokenStoreName);
        localStorage.removeItem(this.userStoreName);
        sessionStorage.removeItem(this.userStoreName);
        localStorage.removeItem(this.timeStoreName);
        sessionStorage.removeItem(this.timeStoreName);
        localStorage.removeItem('class_info');
        localStorage.removeItem('_school');
    },
    cacaheTime(time) {
        if (!time || time < 0) {
            time = 0;
        }
        localStorage.setItem(this.timeStoreName, time);
    },
    clearTime() {
        localStorage.removeItem(this.timeStoreName);
    },
    takeTime() {
        let time = localStorage.getItem(this.timeStoreName) || 0;
        if (parseInt(time) <= 0) {
            time = 0;
        }
        return parseInt(time);
    }

}
