<template>
    <el-row class="main" style="background-color: #cfd5d8;" :gutter="1">
        <el-row>
            <el-col style="padding-bottom: 2px;">{{ getTime }}</el-col>
        </el-row>
    </el-row>
</template>

<script>
import setting from "@/config/setting";
export default {
    name: "time-countdown",

    computed: {
        getTime() {
            if (this.time == 0) {
                return "00:00"
            }
            let m = 0;
            if (this.time >= 60) {
                m = parseInt(this.time / 60)
                if (m < 10) {
                    m = '0' + m;
                }
            }
            let s = 0;
            if (this.time >= 60) {
                s = this.time % 60;
            } else {
                s = this.time;
            }
            if (s < 10) {
                s = '0' + s;
            }
            return m + ":" + s;
        }
    },
    data() {
        return {
            time: 0,
            flag30: false,
            flag20: false,
            flag10: false,
            internal: null,
        }
    },
    created() {
        this.time = setting.takeTime();
        console.log("获取时间", this.time)
        this.internal = setInterval(() => {
            if (this.time >= 1) {
                if (this.time <= 1800 && this.time > 1200 && !this.flag30) {
                    this.popUp("您已使用本系统达到30分钟啦，建议您退出系统让眼睛休息一下吧！")
                    this.flag30 = true;
                } else if (this.time <= 1200 && this.time > 600 && !this.flag20) {
                    this.popUp("您已疲劳用眼达到40分钟啦，建议您退出系统让眼睛休息一下吧！")
                    this.flag20 = true;
                } else if (this.time <= 600 && this.time > 0 && !this.flag10) {
                    this.popUp("您已疲劳用眼达到50分钟啦，建议您退出系统让眼睛休息一下吧！")
                    this.flag10 = true;
                }
                if (setting.takeToken()) {
                    setting.cacaheTime(this.time - 1);
                    if (this.time <= 1) {
                        clearInterval(this.internal);
                        setting.clear();
                        this.$message.info("登录已过期,请重新登录");
                        setTimeout(() => {
                            this.$router.push("/login");
                        }, 500)
                    }
                }

            }

            this.time = setting.takeTime();
        }, 1000)
    },
    methods: {
        popUp(msg) {
            this.$notify({
                title: '提示',
                message: msg,
                position: 'bottom-right'
            });
        }
    },
    destroyed() {
        clearInterval(this.internal);
        this.time = 0;
    }
}
</script>

<style scoped>
.line {
    background-color: #000000;
    height: 2px;
    border-radius: 1px;
}

.row {
    background-color: #000000;
    height: 5px;
    width: 2px;
    border-radius: 1px;
    display: flex;
}

.left {
    justify-content: left;
}

.main {
    min-width: 100px;
    min-height: 50px;
    border: 20px solid transparent;
    -moz-border-image: url("~@/assets/test/time.png") 21 26 round;
    /* Old Firefox */
    -webkit-border-image: url("~@/assets/test/time.png") 21 26 round;
    /* Safari and Chrome */
    -o-border-image: url("~@/assets/test/time.png") 21 26 round;
    /* Opera */
    border-image: url("~@/assets/test/time.png") 21 26 round;
    color: #000000;
    font-size: 20px;
}
</style>