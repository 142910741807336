/**
 * axios配置
 */
import axios from 'axios'
import router from '../router';
import setting from './setting';

import Message from "element-ui/packages/message/src/main";


// 设置统一的url
axios.defaults.baseURL = "https://api.jxgy.org.cn/api/";

/* 请求拦截器 */
axios.interceptors.request.use((config) => {
    // config.baseURL = "/api"
    // 添加token到header
    const token = setting.takeToken();
    if (token) {
        config.headers[setting.tokenHeaderName] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */
axios.interceptors.response.use((res) => {
    console.log(res);
    // 登录过期处理
    if (res.data.code === 401) {
        setting.clear();
        goLogin();
        return Promise.reject(new Error(res.data.message));
    }
    // // token自动续期
    // const access_token = res.headers[setting.tokenHeaderName];
    // if (access_token) {
    //   setting.cacheToken(access_token);
    // }
    return res.data;
}, (error) => {
    /*未登录授权*/
    console.log(error.response.data.code)
    if (error.response.data.code === 100) {
        Message.error("登录过期,请重新登录")
        setting.clear();
        goLogin();
    }
    return Promise.reject(error);
});



/**
 * 跳转到登录页面
 */
function goLogin(reload) {
    if (reload) {
        location.replace('/login');  // 这样跳转避免再次登录重复注册动态路由
    } else {
        const path = router.currentRoute.path;
        return router.push({
            path: '/login',
            query: path && path !== '/' ? { form: path } : null
        });
    }
}

export {
    axios
}