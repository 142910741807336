<template>
    <el-container>
        <layout-head></layout-head>
        <el-main>
            <router-view />
        </el-main>
        <el-footer >
            Copyright 2022 陶行知教育基金会
        </el-footer>
    </el-container>

</template>

<script>
    import LayoutHead from "@/components/head";

    export default {
        name: "layout-index",
        components: {LayoutHead},
        data(){
            return {
                user: {},
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style scoped>


    .el-main{
        height: 100%;
        width: 100%;
        background-image: url("~@/assets/image/main/main_bg.png");
        background-repeat: no-repeat;
        background-size: cover;

    }

    .el-container{
        height: 100%;
    }
    .el-footer{
        background-color: #06a7f8;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        font-size: 20px;
    }


</style>