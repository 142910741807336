import { axios } from '@/config/axios-config'


/*登录*/
export function doLogin(parameter) {
    return axios.post('/student/login', parameter);
    // return axios({
    //     url: '/student/login',
    //     method: 'post',
    //     parameter
    // })
}

/*修改密码*/
export function updatePassword(parameter) {
    return axios({
        url: '/updatePWD',
        method: 'post',
        params: parameter
    })
}

/*退出登录*/
export function signOut(parameter) {
    return axios({
        url: '/student/loginOut',
        method: 'post',
        params: parameter
    })
}


/*获取验证码*/
export function captcha(parameter) {
    return axios({
        url: '/student/captcha',
        method: 'get',
        params: parameter
    })
}